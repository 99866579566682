import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Main } from '../components/Main';
import { Text } from '../components/Text';
import { Secuence } from '../components/Secuence';

const styles = (theme) => ({
  root: {},
  experience: {
    padding: '10px', // Adjusted padding values
    display: 'block',
    border: '1px solid #fae147',
    margin: '10px'
  },
  '@media screen and (min-width: 768px)': {
    experience: {
      padding: 20
    }
  }
});

class Experience extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <Secuence stagger>
          <article>
            <header>
              <h1 style={{ textAlign: 'center' }}>
                <Text>Experience</Text>
              </h1>
            </header>
            <div className={classes.experience}>
              <h4>
                <Text>Web Developer / IT Specialist</Text>
              </h4>
              <h5>
                <Text>2021 - Present</Text>
              </h5>
              <p>
                <em>
                  <Text>Polisafety, Thessaloniki, Greece</Text>
                </em>
              </p>
              <ul>
                <li>
                  <Text>
                    Configured and optimized client servers and third-party web
                    hosting to migrate websites, plugins, posts, pages,
                    databases and other business assets as needed.
                  </Text>
                </li>
                <li>
                  <Text>
                    Improved the performance of WordPress websites with browser,
                    page, object and database caching, code minification and
                    content delivery networks to reduce page load times and
                    improve usability.
                  </Text>
                </li>
                <li>
                  <Text>
                    Projects included delivering custom website solutions,
                    landing pages, responsive WordPress themes, plugins,
                    ecommerce solutions and new WordPress functionality.
                  </Text>
                </li>
                <li>
                  <Text>
                    Evaluated established websites, legacy code and
                    infrastructure to provide accurate level-of-effort estimates
                    for new projects.
                  </Text>
                </li>
                <li>
                  <Text>
                    Provided technical support for servers and back-end network
                    systems.
                  </Text>
                </li>
                <li>
                  <Text>
                    Installed and configured appropriate software and functions
                    according to specifications.
                  </Text>
                </li>
                <li>
                  <Text>
                    Developed and maintained local networks in ways that
                    optimize performance.
                  </Text>
                </li>
                <li>
                  <Text>
                    Ensured security of networks and computer
                    systems
                  </Text>
                </li>
              </ul>
            </div>
            <div className={classes.experience}>
              <h4>
                <Text>IT Specialist</Text>
              </h4>
              <h5>
                <Text>2017 - 2019</Text>
              </h5>
              <p>
                <em>
                  <Text>Extreme Services, Malia, Crete</Text>
                </em>
              </p>
              <ul>
                <li>
                  <Text>
                    Provided on-site technical support for IT infrastructure at
                    various client locations.
                  </Text>
                </li>
                <li>
                  <Text>
                    Provided technical support for servers and back-end network
                    systems.
                  </Text>
                </li>
                <li>
                  <Text>
                    Installed and configured appropriate software and functions
                    according to specifications.
                  </Text>
                </li>
                <li>
                  <Text>
                    Developed and maintained local networks in ways that
                    optimize performance.
                  </Text>
                </li>
                <li>
                  <Text>
                    Ensured security of networks and computer
                    systems
                  </Text>
                </li>
                <li>
                  <Text>
                    Provided orientation and guidance to users on how to operate
                    new software and computer equipment
                  </Text>
                </li>
                <li>
                  <Text>
                    Maintained records/logs of repairs and fixes and maintenance
                    schedule
                  </Text>
                </li>
                <li>
                  <Text>
                    Identified computer or network equipment shortages and
                    placed orders
                  </Text>
                </li>
              </ul>
            </div>
            <div className={classes.experience}>
              <h4>
                <Text>IT Specialist</Text>
              </h4>
              <h5>
                <Text>2010 - 2015</Text>
              </h5>
              <p>
                <em>
                  <Text>NET LINE, Stalis, Crete</Text>
                </em>
              </p>
              <ul>
                <li>
                  <Text>
                    Provided on-site technical support for IT infrastructure at
                    various client locations.{' '}
                  </Text>
                </li>
                <li>
                  <Text>
                    Delivered all aspects of technical support including
                    implementation of IT infrastructure at the facility.
                  </Text>{' '}
                </li>
                <li>
                  <Text>
                    Worked to troubleshoot technical issues for customers and
                    internal clients.
                  </Text>
                </li>
                <li>
                  <Text>
                    Provided technical support for servers and back-end network
                    systems.
                  </Text>
                </li>
                <li>
                  <Text>
                    Worked cross functionally with other team members to deliver
                    exceptional work and meet goals on projects
                  </Text>
                </li>
              </ul>
            </div>
          </article>
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(Experience);
